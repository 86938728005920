<template>
  <div>
    <vs-card>
      <form>
        <p>Título da notificação</p>
        <vs-input
          v-validate="'required'"
          name="title"
          v-model="push.title"
          class="w-full mb-6"
        />
        <p>Texto:</p>
        <vs-textarea
          v-validate="'required'"
          name="description"
          v-model="push.description"
          class="w-full mb-6"
        />
        <vs-button class="mr-6" @click="submitAction">Enviar</vs-button>
      </form>
    </vs-card>
  </div>
</template>

<script>
import ggcsApi from "@/services/ggcs";
export default {
  data() {
    return {
      push: {
        title: null,
        description: null,
      },
      error: "",
    };
  },
  methods: {
    validateData() {
      if (this.push.title && this.push.description)
        return this.push.title.length > 3 && this.push.description.length > 3;
      else return false;
    },
    submitAction() {
      if (this.validateData()) {
        const payload = {
          title: this.push.title,
          description: this.push.description,
        };

        this.renderLoading();
        ggcsApi
          .post("news/push", payload)
          .then((res) => {
            this.$vs.loading.close();
            const { data } = res;
            if (data.success) {
              this.notifySuccess();
              this.clearData();
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.error = "Erro ao tentar enviar notificação: " + error;
            this.notifyError();
          });
      } else {
        this.error = "Preencha os campos com pelo menos 3 caracateres";
        this.notifyError();
      }
    },

    renderLoading() {
      this.$vs.loading({
        // container: "#items-list-view",
        scale: 0.45,
      });
    },
    notifySuccess() {
      this.$vs.notify({
        title: "Sucesso",
        text: `Notificação enviada com sucesso`,
        color: "success",
        iconPack: "feather",
        icon: "icon-check",
        position: "top-right",
      });
    },
    notifyError() {
      const text = this.error;
      this.$vs.notify({
        title: "Erro",
        text: text,
        color: "danger",
        iconPack: "feather",
        icon: "icon-check",
        position: "top-right",
      });
    },
    clearData() {
      this.push = {
        title: null,
        description: null,
      };
    },
  },
};
</script>
